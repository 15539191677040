import { RiMoonFill } from "@react-icons/all-files/ri/RiMoonFill";
import { RiSunFill } from "@react-icons/all-files/ri/RiSunFill";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const navLinkItem = {
  paddingRight: "2rem",
};

const navLinkText = {
  color: "var(--textNormal)",
  textDecoration: "none",
};

const navHeader = {
  color: "var(--textNormal)",
  fontSize: "46px",
  fontFamily: "Josefin Sans",
};

const navHeaderPeriod = {
  animation: "animate 2s linear infinite",
  color: "orange",
  fontSize: "46px",
};

const navLinks = {
  paddingRight: "20px",
  display: "flex",
  flexDirection: "row",
  listStyleType: "none",
};

const navLinksAccent = {
  color: "orange",
  paddingRight: "1px",
};

const NavContainer = styled.nav`
  display: flex;
  list-style: none;
  height: 100px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 95%;
  margin: auto;

  @media (max-width: 560px) {
    justify-content: center;
    padding-top: 20px;
  }
`;

if (typeof window != "undefined") {
  let styleSheet = document.styleSheets[0];

  const keyframesFixed = `
  @keyframes animate{
    0%{
    opacity: 0;
    }
    50%{
    opacity: 1;
    }
    100%{
    opacity: 0;
    }
    }
    `;

  styleSheet.insertRule(keyframesFixed, styleSheet.cssRules.length);
}

const Nav = () => {
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    if (isDark) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [isDark]);

  return (
    <NavContainer>
      <div style={navHeader} onClick={() => setIsDark(!isDark)}>
        <Link style={navLinkText} to="/">
          nithun
        </Link>
        <span style={navHeaderPeriod}>_</span>
      </div>
      <div>
        {/* <ul style={navLinks}>
          <li style={navLinkItem}>
            <Link to="/" style={navLinkText}>
              <span style={navLinksAccent}>/ </span>
              Home
            </Link>
          </li>
          <li style={navLinkItem}>
            <Link to="/gallery" style={navLinkText}>
              <span style={navLinksAccent}>/ </span>
              Gallery
            </Link>
          </li>
          <li style={navLinkItem}>
            <Link to="/blog" style={navLinkText}>
              <span style={navLinksAccent}>/ </span>
              Blog
            </Link>
          </li>
          <li style={navLinkItem}>
            <div>
              {isDark ? (
                <RiSunFill onClick={() => setIsDark(!isDark)} />
              ) : (
                <RiMoonFill onClick={() => setIsDark(!isDark)} />
              )}
            </div>
          </li>
        </ul> */}
      </div>
    </NavContainer>
  );
};

export default Nav;
