import React from "react";
import Nav from "./nav";

const HeaderWrapper = ({ children }) => (
  <div>
    <Nav />
    {children}
  </div>
);

export default HeaderWrapper;
